var jQuery = require('jquery');
require('jquery.easing');

window.jQuery = jQuery;
window.$ = jQuery;

require('moment');
// require('bootstrap-five');
// import * as Popper from "@popperjs/core"
// require('bootstrap-five/js/dist/alert');
// require('bootstrap-five/js/dist/button');
// require('bootstrap-five/js/dist/carousel');
// require('bootstrap-five/js/dist/util');
// require('bootstrap-five/js/dist/collapse');
require('bootstrap-five/js/dist/dropdown');
// require('bootstrap-five/js/dist/modal');
// require('bootstrap-five/js/dist/popover');
// require('bootstrap-five/js/dist/scrollspy');
require('webticker/jquery.webticker.js');
require('bootstrap-five/js/dist/tab');
// require('bootstrap-five/js/dist/tooltip');
var cookie = require('js-cookie');
require('tablesorter');

import Swiper from 'swiper/bundle';

// import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
// import bodymovin from 'lottie-web';
require('jquery-countdown');
import Mmenu from 'mmenu-js';

// require('bootstrap-select');
// require('pc-bootstrap4-datetimepicker');
// require('bootstrap-datepicker');

// require('magnific-popup');

// DOM Ready functions using custom async function so it only funs when jQuery has been fully loaded

$(function() {

    initAjaxJS();

    if ($('#cookieAcceptBarShow').length) {
        var tickerSpeed = $("#webTicker").attr("data-speed");

        $('#webTicker').webTicker({
            speed:tickerSpeed
        });
    };

    if ($('#clock').length) {

        var $time = $("#clock").attr("data-time");
        //YYYY/M/D
        $('#clock').countdown($time, function(event) {
            $(this).html(event.strftime('' +
                '<div class="box"><h3>%w</h3><p>Weeks</p></div><div class="box"><h3>%d</h3><p>Days</p></div><div class="box d-md-none d-xl-block"><h3>%H</h3><p>Hours</p></div><div class="box d-md-none d-xl-block"><h3>%M</h3><p>Minutes</p></div>'
            ));
        });
    }

    $('#btn-menu').on('click', function() {
        $(this).toggleClass('open');
        $('.sidebar-offcanvas').toggleClass('active');
    });

    var announcementHeight = $(".announcements").outerHeight();

    $(".fixed-header").css("top", announcementHeight );



    /* $(document).on("scroll",function(){

        if ($(document).scrollTop() > 40){
            $(".header-logos").addClass("header-shrink");
        }else {
            $(".header-logos").removeClass("header-shrink");
        }

        if ($(document).scrollTop() > 0){
            $(".fixed-header").css("top", 0 );
        }else {
            $(".fixed-header").css("top", announcementHeight );
        }
    }); */

     if ($('.tablesorter').length) {

        $('<thead></thead>').prependTo('.tablesorter').append($('.tablesorter tr:first'));
        $('<input class="search form-control form-control--search table-search mb-4" placeholder="Enter Keyword" type="search" data-column="all">').insertBefore('.tablesorter');
        $('.tablesorter').wrap('<div class="table-responsive flip-table"></div>');
        $(".tablesorter").tablesorter({
                widgets: ["filter"],
                widgetOptions : {
            // filter_anyMatch replaced! Instead use the filter_external option
            // Set to use a jQuery selector (or jQuery object) pointing to the
            // external filter (column specific or any match)
            filter_external : '.search',
            // add a default type search to the first name column
            filter_defaultFilter: { 1 : '~{query}' },
            // include column filters
            filter_columnFilters: false,
            filter_placeholder: { search : 'Search...' },
            filter_saveFilters : true,
            filter_reset: '.reset'
            }
        });


    }else{
        $('.search-control').addClass('d-none');
    }


    // init Swiper:
    if($('.sponsorCarousel').length){
        const sponsorSwiper = new Swiper('.sponsorCarousel', {
            // slidesPerView: 1,
            spaceBetween: 24,
            slidesPerView: "auto",
            loop: false,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },
            observer: true,
            observeParents: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            // Responsive breakpoints
            breakpoints: {

                768: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 4,
                }
            },
        });
    }

    if($('.heroSwiper').length){
        const heroSwiper = new Swiper('.heroSwiper', {
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },

            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    }
    if($('.contentSwiper').length){
        const contentSwiper = new Swiper('.contentSwiper', {
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },

            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    }
    if($('.accommodationSwiper').length){
        const accommodationSwiper = new Swiper('.accommodationSwiper', {
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        });
    }

});


//-----------------------------------------------
// Global AJAX / Application Functions
//-----------------------------------------------
function initAjaxJS() {
    cookiesPolicyBar();
}


// Accept cookies
function cookiesPolicyBar() {
    // Check cookie
    if (cookie.get('CSANZ_Announcement') != "active") {
        $('#cookieAcceptBarShow').show();

        $('.mm-menu').css('top', 48);

        $(window).on('scroll', function() {
            var scroll = $(window).scrollTop();

            if (scroll >= 40) { /* scroll top menu out of view */
                $('.mm-menu').css('top', 48);

            } else {
                    $('.mm-menu').css('top', 83);
                }
            });

        }else{
            $('.mm-menu').css('top', 48);

    }

    //Assign cookie on click
    $('.cookieAcceptBarConfirm').on('click', function() {
        cookie.set('CSANZ_Announcement', 'active', { expires: 1 }); // cookie will expire in one day
        $('#cookieAcceptBarShow').slideToggle( "fast" );
        $('.mm-menu').css('top', 48);

        $(window).on('scroll', function() {
            var scroll = $(window).scrollTop();

            $('.mm-menu').css('top', 48);

        });

    });
}

const mmenu = new Mmenu('#mob-menu', {
    'extensions': [
        "pagedim-black"
    ],
}, {
    // configuration
    offCanvas: {
        page: {
                selector: '#offcanvas'
        }
    },
    classNames: {
        selected: "active"
    }
});

const mmenuApi = mmenu.API;
mmenuApi.bind('open:after', function(){
    $('.btn-menu').addClass('open').attr('href','#offcanvas');
});
mmenuApi.bind('close:after', function(){
    $('.btn-menu').removeClass('open').attr('href','#mob-menu');
});